<template>
  <div>
    <MenuNavigation v-if="!loadingPage && loggedUser && !loadingEmployeeData"
      :loggedUser="loggedUser"
      :page="'UploadVaccinationPage'" />

    <div v-if="loadingPage">
      <div class="spinning-loader"></div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-if="!loadingPage && !loadingEmployeeData" class="page-block-item">
        <div class="bodytext general-text text-justify">
          {{ getPageDescription() }}
        </div>
        <div v-if="employeeOptions.length != 1" class="dataentrydiv">
          <div class="label margin-bottom-5">{{ $t('selectEmployeeLabel') }}</div>
          <multiselect v-model="selectedEmployee" :options="employeeOptions" label="label"
            track-by="label" :searchable="true" :allow-empty="false"
            :close-on-select="true" :show-labels="false"
            :placeholder="$t('selectEmployeePlaceHolder')"
            class="margin-bottom-25">
          </multiselect>

        </div>
        <div v-else class="dataentrydiv margin-bottom-25">
          <div v-if="clientAuthType === 'EMAIL'" class="label margin-bottom-5">
            {{ $t('uploadVaccinationPage_employeeEmailLabel') }}
          </div>
          <div v-else class="label margin-bottom-5">{{ $t('employeeNameAndID') }}</div>
          <div>
            <span class="multiselect__single employee-label">{{ selectedEmployee.label }}</span>
          </div>
        </div>

        <WrongUser v-if="selectedEmployee"
          :userName="loggedUser.name"
          :clientAuthType="clientAuthType"
          :displayAdminWarning="employeeOptions.length != 1" />

        <div v-if="!showMainPageArea && loadingEmployeeData" class="dataentrydiv">
          <div class="spinning-loader"></div>
        </div>

        <div v-if="showMainPageArea">
          <div v-if="vaccinations && vaccinations.length > 0 && !partiallyUploaded && !extraShot">
            <div class="dataentrydiv margin-bottom-25">
              <div class="prior-vaccinations">
                <span>
                  <div v-if="showVaxInfo()" class="vaccination-records-title">
                    {{ $t('vaccinationRecords') }}
                  </div>
                  <div v-if="!showVaxInfo()" class="vaccination-records-title">
                    {{ $t('vaccinationRecordsUser') }}
                  </div>
                </span>
                <table v-for="vaccination in vaccinations" v-bind:key="vaccination.id"
                  class="vaccination-records-table">
                  <tr v-for='(item, key) in SHOT_BY_INDEX' :key='key'
                    :set="SHOTS_INPUT[SHOT_BY_INDEX[key]] = vaccination[item]">
                    <td v-if="showVaxInfo() && vaccination[item]"
                      style="text-align: right">
                      {{ $t(item) }} ({{ getVaccineById(getShotInputDate(key).vaccineId).name }})
                    </td>
                    <td v-if="showVaxInfo() && vaccination[item]"
                      style="text-align: left; padding-left: 30px;">
                      {{ getShotInputDate(key).date }}
                    </td>
                  </tr>
                </table>
                <button class="delete-btn" @click="deleteRecord(vaccinations[0].firstDose.date)">
                  {{ $t('deleteRecords') }}
                </button>
              </div>
            </div>
            <div v-if="showBoosterShotButton()" class="dataentrydiv margin-bottom-25">
              <span @click="addExtraShot" class="button w-button uppercase">
                {{ $t('uploadVaccinationPage_addBosterDose') }}
              </span>
            </div>

            <div>
              <div class="general-text text-justify">
                <div v-if="showVaxInfo()">{{ $t('ifVaccinationIncorrect') }}</div>
                <div v-if="!showVaxInfo()">{{ $t('ifVaccinationIncorrectUser') }}</div>
              </div>
              <div class="general-text text-justify">
                {{ getEffectivenessReminder() }}
              </div>
            </div>
            <div class="daterow" v-if="clientAuthType !== 'SSO'">
              <span @click="logout" class="button w-button navigation-menu-button"
                style="margin-right: 20px;">
                {{ $t('logoutOrCreateNewRecord') }}
              </span>
              <span v-if="covidTestTrackingEnabled()" @click="goToUploadCovidTestPage"
                class="button w-button navigation-menu-button"
                style="margin-left: 20px;">
                {{ $t('uploadVaccinationPage_uploadCovidTest') }}
              </span>
            </div>
          </div>
          <div v-else-if="!showCovidUploadInputForm">
            <NoRecordUploaded @uploadVaccine="forceShowCovidUploadInputForm = $event"/>
          </div>
          <div v-else class="dataentrydiv">

            <div v-if="clientAuthType === 'EMAIL'" class="dataentrydiv restaurant-selection">
              <div class="input-label margin-bottom-25">
                {{ getUploadVaccinationPage_enterNameAndRestaurantText() }}
              </div>

              <InputUserName v-model="userName" :disabled="partiallyUploaded || extraShot" />

              <div class="daterow margin-bottom-50">
                <multiselect v-model="selectedRestaurant" :options="restaurantOptions"
                  label="label" track-by="label"
                  :searchable="true" :allow-empty="false" :close-on-select="true"
                  :show-labels="false"
                  :placeholder= "getUploadVaccinationPage_selectRestaurantLabel()"
                  :disabled="partiallyUploaded || extraShot"
                  style="width: 100%">
                </multiselect>
              </div>
            </div>

            <div v-if="!(extraShot && !showVaxInfo())" class="dataentrydiv">
              <div class="input-label margin-bottom-25">
                {{ $t('selectBrandAndDateFirstDose') }}
              </div>
              <div class="daterow margin-bottom-50"
                v-if="!partiallyUploaded || showVaxInfo()">
                <div class="datepick">
                  <multiselect
                    v-model="SHOTS_INPUT[`${SHOT_BY_INDEX[1]}SelectedVaccine`]"
                    @input="vaxInputChanged(1)"
                    :options="vaccineOptions"
                    label="label" track-by="label"
                    :searchable="true" :allow-empty="false" :close-on-select="true"
                    :show-labels="false"
                    :placeholder="$t('selectBrand')"
                    :disabled="partiallyUploaded || extraShot">
                  </multiselect>
                </div>
                <div class="datepick">
                  <date-pick v-model="SHOTS_INPUT[SHOT_BY_INDEX[1]]"
                    :isDateDisabled="(date) => {
                       return isFutureDate(date) || extraShot;
                    }">
                    <template v-slot:default="{toggle, inputValue}">
                      <button @click="toggle" class="vaccination-btn">
                        {{ inputValue || $t('selectDate') }}
                      </button>
                    </template>
                  </date-pick>
                </div>
              </div>
              <div v-else class="daterow margin-bottom-50">
                  <div class="prior-vaccinations">
                    <span class="vaccination-records-title">
                      {{ $t('firstDoseAlreadyUploaded') }}
                    </span>
                  </div>
              </div>
              <div class="" v-if="partiallyUploaded">
                <button class="deletefirtsdose-btn" @click="deleteRecord(getShotInputDate(1))">
                  {{ $t('deleteFirstDose') }}
                </button>
              </div>
              <div class="input-label margin-bottom-25"
                v-if="multipleDoseVaccine">
                {{ $t('selectBrandAndDateSecondDose') }}
              </div>
              <div class="daterow margin-bottom-50"
                v-if="multipleDoseVaccine">
                <div class="datepick">
                  <multiselect
                    v-model="SHOTS_INPUT[`${SHOT_BY_INDEX[2]}SelectedVaccine`]"
                    @input="vaxInputChanged(2)"
                    :options="vaccineOptions"
                    label="label" track-by="label"
                    :searchable="true" :allow-empty="true" :close-on-select="true"
                    :show-labels="false"
                    :placeholder="$t('selectBrand')"
                    :disabled="extraShot">
                  </multiselect>
                </div>
                <div class="datepick">
                  <date-pick v-model="SHOTS_INPUT[SHOT_BY_INDEX[2]]"
                    :isDateDisabled="(date) => {
                       return isDaysBetweenDoses(date) || extraShot;
                    }">
                    <template v-slot:default="{toggle, inputValue}">
                      <button @click="toggle" class="vaccination-btn">
                        {{ inputValue || $t('selectDate') }}
                      </button>
                    </template>
                  </date-pick>
                </div>
              </div>

              <!-- third or booster dose -->
              <div class="input-label margin-bottom-25"
                v-if="showBoosterOnInitialUpload">
                {{ $t('uploadVaccinationPage_addThirdOrBosterDose') }}
              </div>
              <div class="daterow margin-bottom-50"
                v-if="showBoosterOnInitialUpload">
                <div class="datepick">
                  <multiselect
                    v-model="SHOTS_INPUT[`${SHOT_BY_INDEX[getThirdOrBoosterIdx()]}SelectedVaccine`]"
                    :options="vaccineOptions"
                    label="label" track-by="label"
                    :searchable="true" :allow-empty="true" :close-on-select="true"
                    :show-labels="false"
                    :placeholder="$t('selectBrand')">
                  </multiselect>
                </div>
                <div class="datepick">
                  <date-pick v-model="SHOTS_INPUT[SHOT_BY_INDEX[getThirdOrBoosterIdx()]]"
                    :isDateDisabled="(date) => {
                       return isDaysBeforeLastInputDose(date);
                    }">
                    <template v-slot:default="{toggle, inputValue}">
                      <button @click="toggle" class="vaccination-btn">
                        {{ inputValue || $t('selectDate') }}
                      </button>
                    </template>
                  </date-pick>
                </div>
              </div>
            </div>

            <div class="dataentrydiv">
              <div class="input-label margin-bottom-25" v-if="extraShot">
                {{ $t('uploadVaccinationPage_addBosterDose') }}:
              </div>
              <div v-if="extraShot" class="margin-bottom-25" >
                <div v-for='(item, key) in SHOT_BY_INDEX' :key='key' >
                  <div v-if="showBoosterShotInput(key)" class="daterow margin-bottom-25">
                    <div class="datepick">
                      <multiselect
                        v-model="SHOTS_INPUT[SHOT_BY_INDEX[key] + 'SelectedVaccine']"
                        :options="vaccineOptions"
                        label="label" track-by="label"
                        :searchable="true" :allow-empty="true" :close-on-select="true"
                        :show-labels="false"
                        :placeholder="$t('selectBrand')"
                        :disabled="disableExtraShotInput(key)">
                      </multiselect>
                    </div>
                    <div class="datepick">
                      <date-pick v-model="SHOTS_INPUT[SHOT_BY_INDEX[key]]"
                        :isDateDisabled="(date) => {
                          return isDaysBeforeLastDose(date) || disableExtraShotInput(key);
                        }">
                        <template v-slot:default="{toggle, inputValue}">
                          <button @click="toggle" class="vaccination-btn">
                            {{ inputValue || $t('selectDate') }}
                          </button>
                        </template>
                      </date-pick>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="dataentrydiv margin-bottom-25">
              <div class="input-label margin-bottom-15">
                {{ getUploadTextObject().title }}:
              </div>
              <div class="general-text margin-bottom-15"
                v-html="getUploadInstructionMobileText()"></div>
              <div class="general-text margin-bottom-15"
                v-html="$t('uploadOnComputer')"></div>
              <div class="general-text margin-bottom-25">
                {{ getUploadTextObject().description }}
              </div>
              <vue-dropzone ref="uploadVueDropzone" id="dropzoneId"
                :options="dropzoneOptions" :useCustomSlot="true"
                @vdropzone-file-added="fileAdded"
                @vdropzone-removed-file="fileRemoved"
                @vdropzone-sending="uploadingImage"
                @vdropzone-error="errorUploading"
                @vdropzone-queue-complete="uploadCompleted">
                <div class="dropzone-custom-content">
                  <img src="@/assets/images/upload.svg" loading="lazy" alt="" class="uploadicon">
                  <div>{{ $t('uploadAreaInfo') }}</div>
                </div>
              </vue-dropzone>
            </div>

            <div v-if="showPageInstructions()"
              class="page-block-item bodytext general-text text-justify">
              <p> {{ $t('disclaimerSodexo') }} </p>
              {{ getPageInstructions() }}
            </div>

            <div class="dataentrydiv" style="width: 100%">
              <span v-if="!submitting" @click="submitForm" class="button w-button">
                {{ $t('saveYourChanges') }}
              </span>
              <span v-else class="button w-button">
                <div class="lds-loader"><div></div><div></div><div></div></div>
              </span>
            </div>
          </div>

          <p v-if="showCovidTestAndCounter && covidTests.length > 0"
              class="dataentrydiv page-title new-section-page">
            {{ $t('covidTestHistory') }}
          </p>

          <ListCovidTest
            v-if="showCovidTestAndCounter && covidTests.length > 0" :covidTestListProp="covidTests"
            :key="Object.keys(covidTests).join(',')"
            @deleted="covidTestDeleted($event)" />

          <div v-if="false"
            class="dataentrydiv margin-bottom-15 new-section-page">
            <p class="page-title">
              {{ $t('uploadVaccinationPage_vaccinationCount') }}
            </p>
            <p class="general-text">
            {{ getVaccinationCountDescription() }}
            </p>
            <span @click="getFullyVaccinatedCount()"
              class="button w-button navigation-menu-button
                    vaccination-summary-btn uppercase margin-bottom-15">
              {{ $t('uploadVaccinationPage_showFullyVaccinatedCount') }}
            </span>
            <div v-if="showFullyVaccinatedCountSpinner">
              <div class="spinning-loader"></div>
            </div>
            <p v-if="showFullyVaccinatedCount" class="general-text margin-bottom-0 vax-count">
              {{ fullyVaccinatedCount }} {{ $t('uploadVaccinationPage_employeesLabel') }}
            </p>
          </div>

        </div>
        <div class="dataentrydiv new-section-page">
          <privacy-policy />
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePick from 'vue-date-pick';
import vueDropzone from 'vue2-dropzone';
import { uuid } from 'vue-uuid';
import Path from 'path';

import AuthApi from '@/api/auth.api';
import VaccineApi from '@/api/vaccine.api';
import EmployeeApi from '@/api/employee.api';
import UploadApi from '@/api/upload.api';
import Utils from '@/utils/utils';

import MenuNavigation from '@/components/common/user/MenuNavigation.vue';
import WrongUser from '@/components/common/user/WrongUser.vue';
import InputUserName from '@/components/common/user/InputUserName.vue';
import NoRecordUploaded from '@/components/covid-vax/NoRecordUploaded.vue';
import ListCovidTest from '@/components/covid-test/ListCovidTest.vue';

import 'vue-date-pick/dist/vueDatePick.css';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

function mapToSelect(label, value) {
  return { label, value };
}

const CLIENT = Utils.getClient();

export default {
  name: 'UploadVaccination',
  props: ['loggedUser'],

  components: {
    Multiselect,
    DatePick,
    vueDropzone,
    ListCovidTest,
    MenuNavigation,
    WrongUser,
    InputUserName,
    NoRecordUploaded,
  },

  data() {
    return {
      SHOT_BY_INDEX: {
        1: 'firstDose',
        2: 'secondDose',
        3: 'thirdDose',
        4: 'fourthDose',
        5: 'fifthDose',
      },
      SHOTS_INPUT: {},
      NEXT_SHOT: 1,
      loadingPage: true,
      loadingEmployeeData: false,
      showMainPageArea: false,
      vaccines: [],
      vaccineOptions: [],
      employees: [],
      employeeOptions: [],
      selectedEmployee: null,
      restaurants: [],
      restaurantOptions: [],
      selectedRestaurant: null,
      vaccinations: [],
      covidTests: [],
      dropzoneOptions: {
        url: UploadApi.getVaccinationEndpoint(),
        // capture: 'camera',
        acceptedFiles: 'image/*,application/pdf',
        autoProcessQueue: false,
        addRemoveLinks: true,
        withCredentials: true,
        headers: {
          'x-sub-client': CLIENT,
        },
      },
      uploadedFiles: [],
      uploadHasError: false,
      submitting: false,
      clientAuthType: undefined,
      userName: {
        firstName: '',
        lastName: '',
      },
      colapseInput: false,
      forceShowCovidUploadInputForm: false,
      partiallyUploaded: false,
      extraShot: false,
      fullyVaccinatedCount: undefined,
      showFullyVaccinatedCount: false,
      showFullyVaccinatedCountSpinner: false,
    };
  },

  created() {
    this.colapseInput = this.$route.params.colapseInput || false;
    this.fetchInitialData();
  },

  methods: {

    isFutureDate(date) {
      const currentDate = new Date();
      return this.partiallyUploaded || date > currentDate;
    },

    isDaysBetweenDoses(date) {
      const currentDate = new Date();
      const firstDoseDate = new Date(this.getShotInputDate(1));
      const firstDoseVaccine = this.getVaccineById(this.getShotInputSelectedVaccine(1).value);
      firstDoseDate.setDate(
        firstDoseDate.getDate() + parseInt(firstDoseVaccine.daysBetweenDoses, 10),
      );
      return date < firstDoseDate || date > currentDate;
    },

    isDaysBeforeLastDose(date) {
      const currentDate = new Date();
      const lastDose = this.getShotInputDate(this.NEXT_SHOT - 1);
      const lastDoseDate = new Date(lastDose);
      return date < lastDoseDate || date > currentDate;
    },

    getDaysToFullImunity() {
      const secondDose = this.getShotInputDate(2);
      if (secondDose && secondDose.date) {
        return this.getVaccineById(secondDose.vaccineId).daystoFullImmunity;
      }
      return this.getVaccineById(this.getShotInputDate(1).vaccineId).daystoFullImmunity;
    },

    resetForm() {
      this.SHOTS_INPUT = {};
      this.NEXT_SHOT = 1;
      this.uploadedFiles = [];
      this.userName.firstName = '';
      this.userName.lastName = '';
      this.selectedRestaurant = null;
      this.partiallyUploaded = false;
      this.showFullyVaccinatedCount = false;
      this.showFullyVaccinatedCountSpinner = false;
    },

    fileAdded() {
      const existingFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      Object.keys(existingFiles).forEach((key) => {
        this.$refs.uploadVueDropzone.removeFile(existingFiles[key]);
      });
    },

    fileRemoved() {
      this.uploadedFiles = [];
    },

    uploadingImage(file, xhr, formData) {
      const fileName = `${uuid.v1()}${Path.extname(file.name)}`;
      formData.append('client', CLIENT);
      formData.append('employeeId', this.selectedEmployee.value);
      formData.append('fileName', fileName);
      this.uploadedFiles.push(fileName);
    },

    errorUploading() {
      this.uploadHasError = true;
      this.uploadedFiles = [];
      this.$refs.uploadVueDropzone.removeAllFiles();
    },

    uploadCompleted() {
      if (!this.uploadHasError) {
        // Complete sending data
        this.completeSubmitForm();
      } else {
        this.submitting = false;
        // Display error message
        this.showErroAlert({
          title: this.$t('uploadError'),
          text: this.$t('uploadErrorInst'),
        });
      }
    },

    submitForm() {
      this.submitting = true;
      this.uploadHasError = false;

      if (!this.validateForm()) {
        this.submitting = false;
        return;
      }

      const queuedFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      if (queuedFiles.length) {
        this.$refs.uploadVueDropzone.processQueue();
      } else {
        // Complete sending data
        this.completeSubmitForm();
      }
    },

    async deleteRecord(vaccinationId) {
      const confirmation = await this.$swal({
        title: this.$t('areYouSure'),
        text: this.$t('noRevert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('deleteConfirm'),
      });
      if (confirmation.isConfirmed) {
        await EmployeeApi.deleteVaccination(this.selectedEmployee.value, vaccinationId);
        this.fetchEmployeeData();
      }
    },

    async completeSubmitForm() {
      const vaccination = {
        clientId: CLIENT,
        employeeId: this.selectedEmployee.value,
        firstDose: {
          date: this.getShotInputDate(1),
          vaccineId: this.getShotInputSelectedVaccine(1).value,
        },
        images: [...this.uploadedFiles],
      };

      if (!this.extraShot) {
        if (this.getShotInputDate(2) && this.getShotInputSelectedVaccine(2)) {
          vaccination.secondDose = {
            date: this.getShotInputDate(2),
            vaccineId: this.getShotInputSelectedVaccine(2).value,
          };
        }

        if (this.getShotInputDate(3) && this.getShotInputSelectedVaccine(3)) {
          vaccination.thirdDose = {
            date: this.getShotInputDate(3),
            vaccineId: this.getShotInputSelectedVaccine(3).value,
          };
        }
      } else {
        for (let i = 2; i <= this.NEXT_SHOT; i += 1) {
          if (this.getShotInputDate(i) && this.getShotInputSelectedVaccine(i)) {
            vaccination[this.SHOT_BY_INDEX[i]] = {
              date: this.getShotInputDate(i),
              vaccineId: this.getShotInputSelectedVaccine(i).value,
            };
          }
        }
      }

      if (this.clientAuthType === 'EMAIL') {
        vaccination.firstName = this.userName.firstName;
        vaccination.lastName = this.userName.lastName;
        vaccination.restaurantId = this.selectedRestaurant.value;
      }

      try {
        await EmployeeApi.submitVaccination(this.selectedEmployee.value, vaccination);
        await this.showSuccesslert({
          title: this.$t('success'),
          text: this.multipleDoseVaccine && !this.getShotInputDate(2) ? this.$t('partialSubmitSuccess') : this.$t('submitSuccess'),
        });
        this.extraShot = false;
        this.fetchEmployeeData();
      } catch (e) {
        console.error(e);
        await this.showErroAlert({
          title: 'Oops..',
          text: this.$t('somethingWentWrong'),
        });
      }
      this.submitting = false;
    },

    validateForm() {
      if (this.clientAuthType === 'EMAIL') {
        if (!this.userName.firstName) {
          this.showWarninglert({
            title: this.$t('uploadVaccinationPage_firstNameMissingTitle'),
            text: this.$t('uploadVaccinationPage_firstNameMissingText'),
          });
          return false;
        }

        if (!this.userName.lastName) {
          this.showWarninglert({
            title: this.$t('uploadVaccinationPage_lastNameMissingTitle'),
            text: this.$t('uploadVaccinationPage_lastNameMissingText'),
          });
          return false;
        }

        if (!this.selectedRestaurant) {
          this.showWarninglert({
            title: this.$t('uploadVaccinationPage_restaurantMissingTitle'),
            text: this.$t('uploadVaccinationPage_restaurantMissingText'),
          });
          return false;
        }
      }

      if (!this.getShotInputSelectedVaccine(1)) {
        this.showWarninglert({
          title: this.$t('validationError'),
          text: this.$t('selectFirstDoseVax'),
        });
        return false;
      }

      if (!this.getShotInputDate(1)) {
        this.showWarninglert({
          title: this.$t('validationError'),
          text: this.$t('selectFirstDoseDate'),
        });
        return false;
      }

      if (this.getShotInputSelectedVaccine(2) || this.getShotInputDate(2)) {
        if (!this.getShotInputSelectedVaccine(2)) {
          this.showWarninglert({
            title: this.$t('validationError'),
            text: this.$t('selectSecondDoseVax'),
          });
          return false;
        }

        if (!this.getShotInputDate(2)) {
          this.showWarninglert({
            title: this.$t('secondDoseDateMissing'),
            text: this.$t('secondDoseInstr'),
          });
          return false;
        }
      }

      if (this.getShotInputSelectedVaccine(3) || this.getShotInputDate(3)) {
        if (!this.getShotInputSelectedVaccine(3)) {
          this.showWarninglert({
            title: this.$t('validationError'),
            text: this.$t('selectBoosterDoseVax'),
          });
          return false;
        }

        if (!this.getShotInputDate(3)) {
          this.showWarninglert({
            title: this.$t('boosterDoseDateMissing'),
            text: this.$t('secondDoseInstr'),
          });
          return false;
        }
      }

      if (this.getShotInputDate(1) && this.getShotInputDate(2)
        && (new Date(this.getShotInputDate(1)) > new Date(this.getShotInputDate(2)))) {
        this.showWarninglert({
          title: this.$t('validationError'),
          text: this.$t('secondDoseWarning'),
        });
        return false;
      }

      if (this.extraShot) {
        for (let i = 1; i <= this.NEXT_SHOT; i += 1) {
          if (!this.getShotInputSelectedVaccine(i)) {
            this.showWarninglert({
              title: this.$t('validationError'),
              text: 'Please select the booster dose vaccine',
            });
            return false;
          }

          if (!this.getShotInputDate(i)) {
            this.showWarninglert({
              title: 'You\'re missing your booster dose date!',
              text: 'Please select a date from the date picker, then try submitting again.',
            });
            return false;
          }
        }
      }

      const queuedFiles = this.$refs.uploadVueDropzone.getQueuedFiles();
      if (this.uploadedFiles.length === 0 && queuedFiles.length === 0) {
        this.showWarninglert({
          title: this.$t('missingCard'),
          text: this.$t('mustUpload'),
        });
        return false;
      }
      return true;
    },

    showErroAlert(message) {
      return this.showAlert({
        ...message,
        icon: 'error',
      });
    },

    showWarninglert(message) {
      return this.showAlert({
        ...message,
        icon: 'warning',
      });
    },

    showSuccesslert(message) {
      return this.showAlert({
        ...message,
        icon: 'success',
      });
    },

    showAlert(message) {
      const options = {
        ...message,
        // position: 'top-end',
      };
      return this.$swal(options);
    },

    async fetchInitialData() {
      try {
        if (!this.loggedUser.roles?.includes('USER')) {
          this.$router.push({ name: 'AuthErrorPage' });
          return;
        }

        this.clientAuthType = this.loggedUser.auth;
        const employeesResult = await EmployeeApi.getAll();
        this.employees = employeesResult.data;
        if (this.clientAuthType === 'EMAIL') {
          this.employeeOptions = this.employees
            .map((employee) => mapToSelect(`${employee.name}`, employee.id));

          const restaurantsResult = await EmployeeApi.getRestaurantList(this.loggedUser.id);
          this.restaurants = restaurantsResult.data;
          this.restaurantOptions = this.restaurants
            .map((restaurant) => mapToSelect(`${restaurant.name}`, restaurant.id));
        } else {
          this.employeeOptions = this.employees
            .map((employee) => mapToSelect(`${employee.name} ${employee.id}`, employee.id));
        }

        const vaccinesResult = await VaccineApi.getAll();
        this.vaccines = vaccinesResult.data;
        this.vaccineOptions = this.vaccines
          .map((vaccine) => mapToSelect(vaccine.name, vaccine.id));

        if (this.$route.params.selectedEmployee) {
          this.selectedEmployee = this.$route.params.selectedEmployee;
        } else if (this.employeeOptions.length === 1) {
          [this.selectedEmployee] = this.employeeOptions;
        }
        this.loadingPage = false;
      } catch (e) {
        console.error(e);
        this.showErroAlert({
          title: 'Oops!',
          text: this.$t('somethingWentWrong'),
        });
        this.loadingPage = false;
        // Should route to error page
      }
    },

    async fetchEmployeeData() {
      this.showMainPageArea = false;
      this.loadingEmployeeData = true;
      this.resetForm();
      try {
        const vaccinationsResult = await EmployeeApi
          .getVaccinationList(this.selectedEmployee.value);

        this.vaccinations = vaccinationsResult.data
          .map((vacc) => {
            const vax = { images: vacc.images };
            Object.values(this.SHOT_BY_INDEX).forEach((val) => {
              if (vacc[val]) {
                vax[val] = vacc[val];
              }
            });
            return vax;
          });

        if (this.vaccinations.length > 0) {
          const currVax = vaccinationsResult.data[0];
          const firstVax = this.getVaccineById(currVax.firstDose.vaccineId);
          this.partiallyUploaded = firstVax.doses === 2 && !currVax.secondDose;

          if (this.partiallyUploaded || this.extraShot) {
            this.userName.firstName = currVax.firstName;
            this.userName.lastName = currVax.lastName;
            this.selectedRestaurant = this.restaurantOptions
              .find((r) => r.value === currVax.restaurantId);

            Object.values(this.SHOT_BY_INDEX).sort().forEach((elemetValue) => {
              if (currVax[elemetValue]) {
                this.NEXT_SHOT += 1;
                this.SHOTS_INPUT[elemetValue] = currVax[elemetValue].date;
                this.SHOTS_INPUT[`${elemetValue}SelectedVaccine`] = this.vaccineOptions
                  .find((v) => v.value === currVax[elemetValue].vaccineId);
              }
            });
          }
        }

        const covidTestListResult = await EmployeeApi
          .getCovidTestList(this.selectedEmployee.value);
        this.covidTests = covidTestListResult.data;
        this.loadingEmployeeData = false;
        this.showMainPageArea = true;
      } catch (e) {
        console.error(e);
        this.loadingEmployeeData = false;
        this.showMainPageArea = true;
        // Should display error
      }
    },

    showVaxInfo() {
      const hiddenForClients = CLIENT === 'bloomin'
        || CLIENT === 'tandmassociates'
        || CLIENT === 'veggiegrill';
      const isManager = this.loggedUser.roles?.includes('MANAGER');
      if (hiddenForClients && !isManager) {
        return false;
      }
      return true;
    },

    getVaccineById(id) {
      return this.vaccines.filter((vacc) => id === vacc.id)[0];
    },

    getEmployeeById(id) {
      return this.employees.filter((emp) => id === emp.id)[0];
    },

    getUploadTextObject() {
      const uploadText = {
        title: this.$t('uploadVaccinationCopyTitle'),
        description: this.$t('uploadVaccinationCopySubtitle'),
      };

      if (CLIENT === 'bloomin') {
        uploadText.title = this.$t('uploadVaccinationCopyTitleForBloomin');
        uploadText.description = this.$t('uploadVaccinationCopySubtitleForBloomin');
      }

      if (CLIENT === 'sodexo') {
        uploadText.title = this.$t('uploadVaccinationCopyTitleForBloomin');
        uploadText.description = this.$t('uploadVaccinationCopySubtitleSodexo');
      }

      return uploadText;
    },

    getUploadInstructionMobileText() {
      let uploadInstructionMobileText = this.$t('uploadOnMobile');

      if (CLIENT === 'sodexo') {
        uploadInstructionMobileText = this.$t('uploadOnMobileSodexo');
      }

      return uploadInstructionMobileText;
    },

    getPageDescription() {
      if (this.loggedUser.roles.includes('MANAGER')) {
        return this.$t('uploadVaccinationPage_managerPageDescription');
      }
      return this.$t('uploadVaccinationPage_pageDescription');
    },

    showPageInstructions() {
      if (CLIENT === 'sodexo' || CLIENT === 'flynnrg') {
        return true;
      }
      return false;
    },

    getPageInstructions() {
      if (CLIENT === 'sodexo') {
        return this.$t('uploadVaccinationInstruction1Sodexo');
      }
      if (CLIENT === 'flynnrg') {
        return this.$t('uploadVaccinationInstruction1Flynnrg');
      }
      return '';
    },

    getEffectivenessReminder() {
      if (CLIENT === 'flynnrg') {
        return this.$t('effectivenessReminder1Flynnrg');
      }
      return `${this.$t('effectivenessReminder1')} 
        ${this.getDaysToFullImunity()} ${this.$t('effectivenessReminder2')}`;
    },

    getVaccinationCountDescription() {
      if (CLIENT === 'flynnrg') {
        return this.$t('uploadVaccinationPage_vaccinationCountDescriptionFlynnrg');
      }
      return this.$t('uploadVaccinationPage_vaccinationCountDescription');
    },

    getUploadVaccinationPage_selectRestaurantLabel() {
      if (CLIENT === 'blazepizza' || CLIENT === 'centerplate') {
        return this.$t('uploadVaccinationPage_selectRestaurantLabelForBlaze');
      }
      if (CLIENT === 'windham') {
        return this.$t('uploadVaccinationPage_selectDepartmentLabel');
      }
      return this.$t('uploadVaccinationPage_selectRestaurantLabel');
    },

    getUploadVaccinationPage_enterNameAndRestaurantText() {
      if (CLIENT === 'blazepizza'
          || CLIENT === 'centerplate'
          || CLIENT === 'saucepizzaandwine') {
        return this.$t('uploadVaccinationPage_enterNameAndLocationText');
      }
      if (CLIENT === 'windham') {
        return this.$t('uploadVaccinationPage_enterNameAndDepartmentText');
      }
      return this.$t('uploadVaccinationPage_enterNameAndRestaurantText');
    },

    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },

    goToUploadCovidTestPage() {
      this.$router.push({ name: 'UploadCovidTestPage' });
    },

    covidTestDeleted(testDate) {
      this.covidTests = this.covidTests
        .filter((ct) => ct.testDate !== testDate);
    },

    addExtraShot() {
      this.extraShot = true;
      this.fetchEmployeeData();
    },

    disableExtraShotInput(vaccineIndex) {
      if (this.extraShot
        && this.NEXT_SHOT > vaccineIndex
        && this.getShotInputDate(vaccineIndex)) {
        return true;
      }
      return false;
    },

    getShotInputDate(index) {
      return this.SHOTS_INPUT[this.SHOT_BY_INDEX[index]];
    },

    getShotInputSelectedVaccine(index) {
      return this.SHOTS_INPUT[`${this.SHOT_BY_INDEX[index]}SelectedVaccine`];
    },

    showBoosterShotButton() {
      const maxBooster = Math.max(...Object.keys(this.SHOT_BY_INDEX));
      return !this.vaccinations
        || !this.vaccinations[0]
        || !this.vaccinations[0][this.SHOT_BY_INDEX[maxBooster]];
    },

    showBoosterShotInput(vaccineIndex) {
      const nonBooster = vaccineIndex !== '1'
        && (vaccineIndex !== '2' || !this.multipleDoseVaccine);

      let show = nonBooster;
      if (this.showVaxInfo()) {
        show = nonBooster && vaccineIndex <= `${this.NEXT_SHOT}`;
      } else {
        show = nonBooster && vaccineIndex === `${this.NEXT_SHOT}`;
      }
      return show;
    },

    async getFullyVaccinatedCount() {
      this.fullyVaccinatedCount = null;
      this.showFullyVaccinatedCount = false;
      const vaccinationSummary = await EmployeeApi
        .getVaccinationSummary(this.selectedEmployee.value);
      this.fullyVaccinatedCount = vaccinationSummary.data.fullyVaccinated || 0;
      this.showFullyVaccinatedCount = true;
    },

    covidTestTrackingEnabled() {
      return this.loggedUser.features?.includes('COVID_TEST_TRACKING');
    },

    getThirdOrBoosterIdx() {
      const isFirstDoseInformed = this.getShotInputSelectedVaccine(1)
        && this.getShotInputDate(1);
      const isSecondDoseInformed = this.getShotInputSelectedVaccine(2)
        && this.getShotInputDate(2);

      if (!this.multipleDoseVaccine && isFirstDoseInformed) {
        return 2;
      }
      if (this.multipleDoseVaccine && isSecondDoseInformed) {
        return 3;
      }
      return 0;
    },

    isDaysBeforeLastInputDose(date) {
      const currentDate = new Date();
      const lastDose = this.getShotInputDate(2) || this.getShotInputDate(1);
      const lastDoseDate = new Date(lastDose);
      return date < lastDoseDate || date > currentDate;
    },

    vaxInputChanged(idx) {
      const maxBooster = Math.max(...Object.keys(this.SHOT_BY_INDEX));
      for (let i = (idx + 1); i <= maxBooster; i += 1) {
        delete this.SHOTS_INPUT[`${this.SHOT_BY_INDEX[i]}SelectedVaccine`];
        delete this.SHOTS_INPUT[`${this.SHOT_BY_INDEX[i]}`];
      }
    },
  },

  computed: {
    multipleDoseVaccine() {
      const selected = this.getShotInputSelectedVaccine(1);
      if (!selected) return false;

      const vaccine = this.getVaccineById(selected.value);
      return this.getShotInputDate(1) ? vaccine.doses && vaccine.doses > 1 : false;
    },

    showCovidUploadInputForm() {
      return this.extraShot
        || !this.colapseInput
        || this.forceShowCovidUploadInputForm;
    },

    showCovidTestAndCounter() {
      return !this.showCovidUploadInputForm
        || (this.vaccinations
        && this.vaccinations.length > 0
        && !this.partiallyUploaded && !this.extraShot);
    },

    showBoosterOnInitialUpload() {
      const isFirstDoseInformed = this.getShotInputSelectedVaccine(1)
        && this.getShotInputDate(1);
      const isSecondDoseInformed = this.getShotInputSelectedVaccine(2)
        && this.getShotInputDate(2);

      let show = false;
      if (this.multipleDoseVaccine) {
        show = isFirstDoseInformed && isSecondDoseInformed;
      } else {
        show = isFirstDoseInformed;
      }
      return (!this.vaccinations
        || this.vaccinations.length === 0
        || (this.partiallyUploaded && this.vaccinations.length === 1))
        && show;
    },
  },

  watch: {
    fullyVaccinatedCount() {
      this.showFullyVaccinatedCountSpinner = !this.fullyVaccinatedCount
        && !this.showFullyVaccinatedCount;
    },
    selectedEmployee() {
      if (this.selectedEmployee) {
        this.fetchEmployeeData();
        this.extraShot = false;
      }
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .dz-progress {
    display: none !important;
  }

  /* Date Pick CSS */
  .vdpComponent {
    width: 100%;
  }

  .vdpComponent.vdpWithInput  > button {
    border: 1px solid#e8e8e8;
    border-radius: 8px;
  }

  .vdpCell.today {
    color: red;
  }

  .multiselect__tags {
    font-size: 16px !important;
    border: 1px solid #cccccc;
  }

  @media screen and (max-width: 479px) {
  }

</style>
<style scoped>
  /* Animation CSS */
 .fade-enter, .slide-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 0.25s ease-out;
  }

  .fade-leave-active {
    transition: opacity 0.25s ease-out;
    opacity: 0;
  }

  .slide-enter-active {
    animation: slide-in 0.25s ease-out forwards;
    transition: opacity 0.25s;
  }

  @keyframes slide-in {
    from {
      transform: translateX(20px);
    }
    to {
      transform: rotate(0);
    }
  }

  /* Submit loader */

  .lds-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    border: 1px solid #5031D0;
  }
  .lds-loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-loader {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }

  /* Vue Dropzone CSS */
  .vue-dropzone {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 300px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px dashed #97D4FF;
    border-radius: 10px;
    background-color: #E8F8FF;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    color: #4D497E;
    font-size: 22px;
    padding: 100px;
  }

  .vue-dropzone:hover {
    background-color: rgba(63, 119, 225, 0.28);
  }

  /* Multi Select CSS */
  .multiselect {
    width: 100%;
    min-width: 500px;
    font-weight: 400;
  }

  .multiselect__option--selected {
    font-weight: 400;
  }

  /* Page CSS */

  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .underline-link {
    cursor:pointer;
    text-decoration: none;
    text-decoration: underline;
  }

  .nav-buttons {
    padding-top: 0px !important;
  }

  .navigation-button {
    width: 250px;
    margin: 0 7px;
  }

  .wellness-menu-button, .wellness-menu-button:hover {
    border: 0px;
    color: #ffffff;
    background-color: #000000
  }

  .navigation-menu-button {
    height: 70px;
    background-color: #ffffff;
    border: 1px solid #5031D0;
    color: #5031D0;
  }

  .navigation-menu-button:hover {
    color: #ffffff;
    background-color: #5031D0;
  }

  .vaccination-btn {
    width: 270px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    align-items: center;
    display: inline-grid;
  }

  .vaccination-summary-btn {
    width: 350px;
  }

  .uploadicon {
    width: 40px;
    margin-bottom: 25px;
  }

  .new-section-page {
    margin-top: 50px;
  }

  .herolist {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .list-item {
    margin-bottom: 15px;
  }

  .daterow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 800px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .daterow-nav-buttons {
    justify-content: center;
  }

  .restaurant-selection input {
    border-radius: 4px;
  }

  .restaurant-selection > .daterow > .multiselect {
    margin: 0 auto;
  }

  .first-name-input {
    margin-right: 10px;
  }

  .last-name-input {
    margin-left: 10px;
  }

  .datepick {
    text-align: center;
  }

  .employee-label {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 0px;
  }

  .vaccination-records-title {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .vax-count {
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
  }

  .prior-vaccinations {
    width: 100%;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
  }

  .prior-vaccinations span {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #3C3C3C;
  }

  .prior-vaccinations table {
    margin: 0;
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;

  }

  .prior-vaccinations td {
    text-align: center;
    max-width: 100px;
    width: 50%;
    height: 20px;
    padding-bottom: 15px;
  }

  .delete-btn {
    width: 150px;
    height: 30px;
    font-size: 12.5px;
    font-weight: 500;
    align-items: center;
    display: inline-grid;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .delete-btn:hover {
    background-color: #5031D0;
    color: #FFFFFF;
  }

  .deletefirtsdose-btn {
    width: 200px;
    font-size: 12.5px;
    font-weight: 500;
    align-items: center;
    display: inline-grid;
    background: #F3F3F3;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none;
    margin-bottom: 15px;
    height: 35px;
  }

  .dropzone-custom-content {
    line-height: 27px;
  }

  .admin-screen-warn {
    background: #ECECEC;
    border-radius: 8px;
    padding: 15px 25px;
  }

  @media screen and (max-width: 479px) {
    /* Vue Dropzone CSS */
    .vue-dropzone  {
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      text-align: center;
      padding: 0px;
    }

    /* Multi Select CSS */
    .multiselect {
      width: 100%;
      min-width: 0px;
    }

    /* Page CSS */
    .daterow {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .daterow .vaccination-btn {
      margin-top: 15px;
    }

    .datepick {
      width: 100%;
    }

    .first-name-input {
      margin-right: 0px;
    }

    .last-name-input {
      margin-left: 0px;
    }

    .navigation-menu-button {
      margin-bottom: 15px !important;
      margin-right: 0px !important;
      margin-left: 0px !important;
    }

    .vaccination-summary-btn {
      width: 100%;
    }

    .vaccination-btn {
      width: 100%;
    }
  }

</style>

<template>
  <div class="page-block-item container">
    <h3 class="general-text">
      {{ $t('covidVaxNoRecordUpload_title') }}
    </h3>
    <div class="general-text"  v-html="$t('covidVaxNoRecordUpload_first')">
    </div>
    <div class="general-text">
      {{ $t('covidVaxNoRecordUpload_second') }}
    </div>
    <div class="general-text">
      {{ $t('covidVaxNoRecordUpload_third') }}
    </div>
    <div class="button-container margin-bottom-15">
      <span @click="uploadVaccine" class="button w-button uppercase">
        {{ $t('covidVaxNoRecordUpload_button') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoRecordUploaded',
  methods: {
    uploadVaccine() {
      this.$emit('uploadVaccine', true);
    },
  },
};
</script>

<style scoped>
  h3 {
    font-size: 28px;
    font-weight: 700;
  }

  .container {
    font-size: 14px;
    text-align: center;
  }

  .button-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   font-weight: 700;
  }

  @media screen and (max-width: 479px) {
    h3 {
      line-height: normal;
    }
  }
</style>

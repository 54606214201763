<template>
  <div class="page-block-item daterow">
    <input class="w-input first-name-input" type="text"
      ref="firstName"
      :value="value.firstName"
      :placeholder="$t('firstNameLabel')"
      :disabled="disabled"
      @input="updateInput()" />
    <input class="w-input last-name-input" type="text"
      ref="lastName"
      :value="value.lastName"
      :placeholder="$t('lastNameLabel')"
      :disabled="disabled"
      @input="updateInput()" />
  </div>
</template>

<script>
export default {
  name: 'InputUserName',
  props: ['value', 'disabled'],

  methods: {
    updateInput() {
      this.$emit('input', {
        firstName: this.$refs.firstName.value,
        lastName: this.$refs.lastName.value,
      });
    },
  },

};
</script>
<style scoped>
  input {
    border-radius: 4px;
  }
  .first-name-input {
    margin-right: 10px;
  }

  .last-name-input {
    margin-left: 10px;
  }

  .daterow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 800px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  @media screen and (max-width: 479px) {
    .daterow {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .first-name-input {
      margin-right: 0px;
    }

    .last-name-input {
      margin-left: 0px;
    }
  }

</style>

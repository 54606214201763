<template>
  <div v-if="this.interalCovidTestList && this.interalCovidTestList.length > 0" class="tsd-panel">
    <datatable :columns="tableColumns" :data="covidTests"></datatable>
  </div>
</template>

<script>
import Vue from 'vue';
import EmployeeApi from '@/api/employee.api';

let eventBus;

Vue.component('header-with-sublabel', {
  template: '<span>{{ getHeader.label }}<br /><span class="sub">{{ getHeader.subLabel }}</span></span>',
  props: ['column'],
  computed: {
    getHeader() {
      return this.column;
    },
  },
});

Vue.component('delete-row', {
  template: `
    <button class="delete-btn" @click="emitDelete">
      {{ $t('delete') }}
    </button>
  `,
  props: ['row'],
  methods: {
    emitDelete() {
      eventBus.$emit('deleteCovidTest', this.row);
    },
  },
});

export default {
  name: 'CovidTestList',

  props: {
    covidTestListProp: Array,
  },

  data() {
    return {
      dataTableColumns: [
        { label: 'Covid Test Date', field: 'testDate' },
        {
          label: 'Is My Test Still Valid?',
          subLabel: '(You must have a negative test every 7 days or less)',
          field: 'testDate',
          headerComponent: 'header-with-sublabel',
          representedAs: ({ isValid, testResult }) => {
            let badgeHTML = '';
            if (testResult === 'POSITIVE') {
              badgeHTML = '<span class="badge critical">COVID POSITIVE</span>';
            } else if (isValid) {
              badgeHTML = '<span class="badge success">VALID</span>';
            } else {
              badgeHTML = '<span class="badge warn">TEST TOO LONG AGO</span>';
            }

            return `<div class="badge-container">${badgeHTML}</div>`;
          },
          interpolate: true,
        },
        {
          label: 'Delete Record',
          subLabel: 'Delete and re-upload if you made an error',
          headerComponent: 'header-with-sublabel',
          component: 'delete-row',
          field: 'testResult',
        },
      ],
      interalCovidTestList: [],
    };
  },

  created() {
    eventBus = new Vue();
  },

  async mounted() {
    this.interalCovidTestList = [...this.covidTestListProp];
    eventBus.$on('deleteCovidTest', async (row) => {
      const confirmation = await this.$swal({
        title: this.$t('areYouSure'),
        text: this.$t('noRevert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('deleteConfirm'),
      });

      const { employeeId, testDate } = row;
      try {
        if (confirmation.isConfirmed) {
          await EmployeeApi.deleteCovidTest(employeeId, testDate);

          this.$emit('deleted', testDate);
        }
      } catch (e) {
        await this.$swal({
          title: 'Oops..',
          text: this.$t('somethingWentWrong'),
          icon: 'error',
        });
      }
    });
  },

  computed: {
    tableColumns() {
      return this.dataTableColumns.map((col) => {
        const column = col;
        column.sortable = false;
        return column;
      });
    },

    covidTests() {
      return this.interalCovidTestList;
    },
  },

};
</script>

<style>
  .tsd-panel table {
    width: 100%;
  }

  .tsd-panel table thead {
    color: #818181;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(20, 15, 15);
  }

  .tsd-panel table thead .sub {
    max-width: 190px;
    font-size: 12px;
    display: inline-block;
    font-weight: 400;
    text-transform: none;
  }

  .tsd-panel table tr td {
    height: 50px;
    text-align: center !important;;
    white-space: nowrap;
  }

  .tsd-panel table tr:nth-child(2n) {
    background-color: #fff !important;
  }

  .badge-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .badge {
    font-weight: 600;

    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;

    /* Rounded border */
    border-radius: 9999px;
    height: 32px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
  }

  .badge.success {
    background-color: #7AF0B0;
    color: #268350;
  }

  .badge.warn {
    background-color: #F3DF27;
    color: #928514;
  }

  .badge.critical {
    background-color: #EE6666;
    color: #FFEDED;
  }

  .delete-btn {
    width: 80px;
    font-size: 12.5px;
    font-weight: 500;
    align-items: center;
    display: inline-grid;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none;
  }

  .delete-btn:hover {
    background-color: #5031D0;
    color: #FFFFFF;
  }
</style>

import HttpClient from '@/api/http-client';

const END_POINT = '/api/v1/vaccines';

const getAll = () => HttpClient.get(END_POINT);
const getAllHep = () => HttpClient.get(`${END_POINT}/hep`);

export default {
  getAll,
  getAllHep,
};

<template>
  <div class="page-block-item">
    <div v-if="!isClientAuthTypeSSO">
      <div @click="logout" class="general-text container">
        <div v-if="displayAdminWarning" class="margin-bottom-15">
          {{ $t('uploadAdminScreenWarning') }},
          <span class="underline-link">
          {{ $t('clickHereToLogin') }}</span>
        </div>
        <div v-if="!displayAdminWarning">
          <div v-if="isClientAuthTypeEmail">
            {{ $t('incorrectUserWarningLink2_employeeEmail') }}
            <span class="underline-link">
            {{ $t('incorrectUserWarningLink3_employeeEmail') }}</span>
          </div>
          <div v-else>
            {{ $t('incorrectUserWarningLink1') }}
            {{ userName }},
            {{ getIncorrectUserWarningLink2() }}
            <span class="underline-link">
            {{ $t('incorrectUserWarningLink3_employeeId') }}</span>
            {{ $t('incorrectUserWarningLink4') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthApi from '@/api/auth.api';
import Utils from '@/utils/utils';

const CLIENT = Utils.getClient();

export default {
  name: 'WrongUser',
  props: ['userName', 'clientAuthType', 'displayAdminWarning'],

  methods: {
    async logout() {
      try {
        await AuthApi.logOut();
      } catch (e) {
        //
      }
    },

    getIncorrectUserWarningLink2() {
      if (CLIENT === 'sodexo') {
        return this.$t('incorrectUserWarningLink2_employeeIdSodexo');
      }
      return this.$t('incorrectUserWarningLink2_employeeId');
    },
  },

  computed: {
    isClientAuthTypeSSO() {
      return this.clientAuthType === 'SSO';
    },

    isClientAuthTypeEmail() {
      return this.clientAuthType === 'EMAIL';
    },
  },
};
</script>
<style scoped>

  hr {
    margin-top: 0;
    margin-bottom: 15px;
  }

  .container {
    background: #ECECEC;
    border-radius: 8px;
    padding: 25px 25px;
  }
</style>

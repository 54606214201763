<template>
  <div class="page-block-item">
    <div v-if="loggedUser && loggedUser.roles"
        class="dataentrydiv nav-buttons">
      <div v-if="showMainMenu()" class="daterow daterow-nav-buttons">
        <span  @click="goToMainMenu"
            class="button w-button navigation-button dashboard-menu-button">
          {{ $t('navigationMenu_goToMainMenu') }}
        </span>
      </div>
      <div v-else class="daterow daterow-nav-buttons">

        <a v-if="wellnessCheckURL && showWellnessLink()"
            class="button w-button navigation-button wellness-menu-button"
            :href="wellnessCheckURL">
          <svg style="width: 25px; margin-right: 10px;" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-3x"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path></svg>
          <span style="padding-right: 20px;">
            {{ $t('navigationMenu_goToWellnessCheckDashboard') }}
          </span>
        </a>

        <span v-if="isManager && showMainDashboardPage" @click="goToMainDashboardPage"
            class="button w-button navigation-button dashboard-menu-button">
          {{ $t('homePage_dashboard') }}
        </span>

        <span v-if="isUser && showUploadVaccinationPage" @click="goToUploadVaccinationPage"
            class="button w-button navigation-button dashboard-menu-button">
          {{ $t('navigationMenu_goToUploadVaccinationPage') }}
        </span>

        <span v-if="isUser && showUploadCovidTestPage && covidTestTrackingEnabled"
          @click="goToUploadCovidTestPage"
            class="button w-button navigation-button dashboard-menu-button">
          {{ $t('navigationMenu_goToUploadCovidTestPage') }}
        </span>

        <span v-if="isUser && showUploadVaccinationPage && covidTestTrackingEnabled"
            @click="goToUploadVaccinationPageWithInputHidden"
            class="button w-button navigation-button dashboard-menu-button">
          {{ getCovidTestHistoryLabel() }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import Utils from '@/utils/utils';

const CLIENT = Utils.getClient();

export default {
  name: 'MenuNavigation',
  props: [
    'loggedUser',
    'wellnessCheckURL',
    'hasMultipleEmployees',
    'page',
  ],

  methods: {

    goToMainDashboardPage() {
      this.$router.push({ name: 'MainDashboardPage' });
    },

    goToUploadVaccinationPage() {
      this.$router.push({ name: 'UploadVaccinationPage' });
    },

    goToUploadVaccinationPageWithInputHidden() {
      this.$router.push({ name: 'UploadVaccinationPage', params: { colapseInput: true } });
    },

    goToUploadCovidTestPage() {
      this.$router.push({ name: 'UploadCovidTestPage' });
    },

    goToMainMenu() {
      this.$router.push({ name: 'HomePage' });
    },

    getCovidTestHistoryLabel() {
      if (this.hasMultipleEmployees) {
        return this.$t('navigationMenu_goToHistoryCovidTestByEmployeePage');
      }
      return this.$t('navigationMenu_goToHistoryCovidTestPage');
    },

    showWellnessLink() {
      if (CLIENT === 'tendergreens'
          || CLIENT === 'blackbeardiner'
          || CLIENT === 'caspers'
          || CLIENT === 'daveandbusters'
          || CLIENT === 'threebbd'
          || CLIENT === 'brinker'
          || CLIENT === 'sodexo'
          || CLIENT === 'flynnrg'
          || CLIENT === 'callaway'
          || CLIENT === 'murata'
          || CLIENT === 'cameronmitchell'
          || CLIENT === 'biscuitville') {
        return false;
      }
      return true;
    },

    showMainMenu() {
      return this.page === 'UploadHepVaccinationPage';
    },
  },

  computed: {
    isManager() {
      return this.loggedUser?.roles?.includes('MANAGER');
    },

    isUser() {
      return this.loggedUser?.roles?.includes('USER');
    },

    showUploadVaccinationPage() {
      return this.page !== 'UploadVaccinationPage';
    },

    showUploadCovidTestPage() {
      return this.page !== 'UploadCovidTestPage';
    },

    showMainDashboardPage() {
      return this.page !== 'MainDashboardPage';
    },

    covidTestTrackingEnabled() {
      return this.loggedUser.features?.includes('COVID_TEST_TRACKING');
    },
  },
};
</script>
<style scoped>

  .nav-buttons {
    padding-top: 0px !important;
  }

  .daterow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 800px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .daterow-nav-buttons {
    justify-content: center;
    flex-wrap: wrap;
  }

  .navigation-button {
    max-width: 200px;
    min-height: 60px;
    height: 60px;
    margin: 7px;
    flex: 1 0 21%;
  }

  .wellness-menu-button, .wellness-menu-button:hover {
    border: 0px;
    color: #ffffff;
    background-color: #000000
  }

  .dashboard-menu-button {
    background-color: #ffffff;
    border: 1px solid #5031D0;
    color: #5031D0;
  }

  .dashboard-menu-button:hover {
    color: #ffffff;
    background-color: #5031D0;
  }

  @media screen and (max-width: 479px) {

    .daterow {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .navigation-button {
      max-width: 100%;
      margin: 0;
    }

    .navigation-button:last-child {
      margin-bottom: 0px !important;
    }

    .navigation-button {
      margin-bottom: 15px !important;
    }
  }

</style>
